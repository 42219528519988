const app = angular.module('app');

app
    .controller('BankIdAuthorizationController', function(
        $scope,
        BankIdApiService,
        $timeout,
        $interval,
        $element,
        $filter,
        $rootScope,
        $window
    ) {
        const pollIntervalMillis = 3000;
        let pollInterval;

        $scope.authStarted = false;
        $scope.authCompleted = false;
        $scope.infoMessage = '';
        $scope.errorMessage = '';
        $scope.mobile_infoMessage = '';
        $scope.mobile_errorMessage = '';
        $scope.ssnPattern = /^(?:18|19|20)?\d{2}[01]\d{3}(-)?\d{4}$/;
        $scope.form = {
            openMbid: $rootScope.isTouchDevice,
        };

        $scope.interacted = function(field) {
            return field.$touched;
        };

        function getPollSleepMillis() {
            return $scope.mobile ? 9000 : 3000;
        }

        function msgPrefix() {
            return $scope.mobile ? 'mobile_' : '';
        }

        function resetMessages() {
            $scope.infoMessage = '';
            $scope.errorMessage = '';
            $scope.mobile_infoMessage = '';
            $scope.mobile_errorMessage = '';
        }

        function cancelPoll() {
            $interval.cancel(pollInterval);
            pollInterval = undefined;
            resetMessages();
            $scope.authStarted = false;
        }

        function hideLoader() {
            $rootScope.$broadcast('event:hideLoader');
        }

        function cancelPollAndHideLoader() {
            cancelPoll();
            hideLoader();
        }

        function launchBankIdApp() {
            $scope.bankIdUrl =
                'bankid:///?autostarttoken=' + $scope.orderResponse.autoStartToken + '&redirect=';

            if (
                $rootScope.isTouchDevice ||
                (window.navigator.userAgent &&
                    window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1)
            ) {
                window.location.href = $scope.bankIdUrl;
            } else {
                $element.find('iframe').attr('src', $scope.bankIdUrl);
            }
        }

        function displayMessage(message, isError) {
            const messageType = isError ? 'errorMessage' : 'infoMessage';
            $scope[msgPrefix() + messageType] = message;
        }

        function displayErrorMessage(message) {
            displayMessage(message, true);
        }

        function handleUnauthorized() {
            cancelPollAndHideLoader();
            $scope.authCompleted = false;
            const message = $filter('translate')('bankid_not_existing_user');
            displayErrorMessage(message);
        }

        function handleComplete() {
            cancelPoll();
            displayMessage($filter('translate')('auth_completed'));
            loginRedirect();
        }

        function loginRedirect() {
            $rootScope.$broadcast('user:loggedIn');
            $window.location.href = '/companyservices/';
        }

        function displayBankIdMessage(response) {
            const message = $filter('translate')('bankid_' + msgPrefix() + response.status);
            displayMessage(message, response.isError);
        }

        function handleError(error) {
            cancelPollAndHideLoader();
            displayBankIdMessage(error);
        }

        function handleResponse(status) {
            const statusHandlers = {
                OUTSTANDING_TRANSACTION: displayBankIdMessage,
                USER_SIGN: displayBankIdMessage,
                COMPLETE: handleComplete,
                NO_CLIENT: displayBankIdMessage,
                USER_CANCEL: handleError,
                STARTED: displayBankIdMessage,
                NON_EXISTING_USER: handleUnauthorized,
            };

            return statusHandlers[status] ? statusHandlers[status] : handleError;
        }

        function startPoll() {
            $timeout(() => {
                pollInterval = $interval(() => {
                    let collectResponse = BankIdApiService.authCollect({
                            orderRef: $scope.orderResponse.orderRef,
                            transactionId: $scope.orderResponse.transactionId,
                    });

                    collectResponse.$promise.then(
                        function(response) {
                            handleResponse(response.status)(response);
                        },
                        function(response) {
                            if (response.status === 400) {
                                cancelPollAndHideLoader();
                                const message = typeof response.data === 'object' ?
                                    response.data.message : response.data;
                                displayMessage(message, true);
                            } else {
                                const handler = handleResponse(response.data.status);
                                if (handler) {
                                    handler(response.data);
                                } else {
                                    handleError(response.data);
                                }
                            }
                        }
                    );
                }, pollIntervalMillis);
            }, getPollSleepMillis());
        }

        $scope.authBid = function() {
            $scope.authStarted = true;
            resetMessages();
            displayBankIdMessage({ status: 'OUTSTANDING_TRANSACTION' });
            const loginBidRequest = BankIdApiService.auth({ personnummer: $scope.username });
            loginBidRequest.$promise.then(
                function(response) {
                    if (response.isError) {
                        handleResponse(response.status)(response);
                        return;
                    }
                    $scope.orderResponse = response.payload;

                    if (!$scope.mobile || ($scope.mobile && $scope.form.openMbid)) {
                        launchBankIdApp();
                    }

                    startPoll();
                },
                function() {
                    const errorMessage = $filter('translate')('bankid_ERROR');
                    cancelPollAndHideLoader();
                    displayMessage(errorMessage, true);
                }
            );
        };

        $scope.authRedirect = function() {
            $window.location.href = './api/auth/bankid-login';
        };

        $scope.$on('$destroy', cancelPollAndHideLoader);
    })
    .directive('bankIdAuth', function() {
        return {
            template:'<form name="bidForm" ng-submit="mobile = false; authBid();"><div class="form-group relative"><ajax-loader auto-hide="false" ajax-url-pattern=".*/api/bankid/auth.*"></ajax-loader><button class="btn btn-primary btn-block" type="submit" ng-disabled="authStarted || authCompleted" translate="Sign in"></button></div><div ng-if="infoMessage.length > 0" class="alert alert-info" ng-bind="infoMessage"></div><div ng-if="errorMessage.length > 0" class="alert alert-danger" ng-bind="errorMessage"></div><iframe id="bankid-frame" src height="0" width="0" style="display: none" ng-if="!mobile"></iframe><a href="https://support.bankid.com/sv" target="_blank" translate="login_bankid_help"></a></form>',
            restrict: 'E',
            controller: 'BankIdAuthorizationController'
        };
    })
    .directive('mobileBankIdAuth', function() {
        return {
            template:'<form name="mbidForm" ng-submit="mobile = true; authBid()" class="relative" novalidate><ajax-loader auto-hide="false" ajax-url-pattern=".*/api/bankid/auth.*"></ajax-loader><div class="form-group" ng-class="{\'has-error\': mbidForm.username.$invalid && interacted(mbidForm.username)}"><label class="control-label" for="mobile-bid-username" translate="login_ssn"></label> <input class="form-control" id="mobile-bid-username" name="username" type="text" pattern="[0-9]*" ng-model="username" required ng-pattern="ssnPattern" placeholder="{{\'Ssn-Format\' | translate}}"><div class="portal-validation-error" ng-if="interacted(mbidForm.username)" ng-messages="mbidForm.username.$error"><div ng-message="required">{{"login_missing_ssn" | translate}}</div><div ng-message="pattern">{{"login_invalid_mbid_ssn" | translate}}</div></div></div><div class="checkbox" ng-if="isTouchDevice"><label class="control-label" for="open-mobile-bid"><input id="open-mobile-bid" type="checkbox" ng-model="form.openMbid"> <span translate="login_open_mbid"></span></label></div><div class="form-group"><button class="btn btn-primary btn-block" type="submit" ng-disabled="authStarted || authCompleted || mbidForm.$invalid" translate="Sign in"></button></div><a href="https://support.bankid.com/sv" target="_blank" translate="login_mbankid_help"></a></form><div ng-if="mobile_infoMessage.length > 0" class="alert alert-info" ng-bind="mobile_infoMessage"></div><div ng-if="mobile_errorMessage.length > 0" class="alert alert-danger" ng-bind="mobile_errorMessage"></div>',
            restrict: 'E',
            controller: 'BankIdAuthorizationController',
        };
    })
    .directive('maxsecurityBankIdAuth', function() {
        return {
            template:'<form name="sbidForm" ng-submit="authRedirect()"><div class="form-group"><button class="btn btn-primary btn-block" type="submit" translate="Sign in"></button></div><a href="https://support.bankid.com/sv" target="_blank" translate="login_bankid_help"></a></form>',
            restrict: 'E',
            controller: 'BankIdAuthorizationController'
        };
    });
