angular.module('app').factory('BankIdApiService', function($resource) {
    return $resource(
        './api/bankid/:action',
        {},
        {
            auth: { method: 'POST', params: { action: 'auth' } },
            authCollect: { method: 'POST', params: { action: 'auth_collect' } },
        }
    );
});
