const module = angular.module('app');

module.controller('SelectCompanyCtrl', ($scope, $route, Preferences, CompanyService) => {

    CompanyService.companiesNotEmptyPromise().then(function (companies) {
        $scope.companies = companies;
        $scope.selectedCompany = CompanyService.selectedCompany;
    }).catch(function (error) {
        console.error("Error at initialise of SelectCompanyCtrl");
        $scope.companies = []
        $scope.selectedCompany = undefined;
    });

    $scope.selectCompany = function (company) {
        $scope.selectedCompany = company;
        CompanyService.selectCompanyAndReloadBasePath(company);
    }
    $scope.openHeartbeat = function() {
        CompanyService.openHeartbeat( CompanyService.selectedCompany);
    };

});
